import React, { useState } from "react"
import { navigate } from "gatsby"
import StartingPoint from "./startingPoint"
import Destinations from "./destinations"
import Interests from "./interests"
import NumberOfPeople from "./people"
import TravelStyle from "./travelStyle"
import TravelDates from "./travelDates"
import Accommodation from "./accommodation"
import Transportation from "./transportation"
import SpecialRequirements from "./specialRequirements"
import ContactInfo from "./contact"
import NavigationButtons from "./navigationButtons"

import "./form.css"

const FormPlanner = () => {
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({
    startingPoint: "",
    destinations: "",
    interests: [],
    numberOfPeople: 1,
    travelStyle: "",
    startDate: "",
    endDate: "",
    accommodation: "",
    transportation: "",
    requirements: "",
    email: "",
  })

  const sections = [
    <StartingPoint formData={formData} setFormData={setFormData} />,
    <Destinations formData={formData} setFormData={setFormData} />,
    <Interests formData={formData} setFormData={setFormData} />,
    <NumberOfPeople formData={formData} setFormData={setFormData} />,
    <TravelStyle formData={formData} setFormData={setFormData} />,
    <TravelDates formData={formData} setFormData={setFormData} />,
    <Accommodation formData={formData} setFormData={setFormData} />,
    <Transportation formData={formData} setFormData={setFormData} />,
    <SpecialRequirements formData={formData} setFormData={setFormData} />,
    <ContactInfo formData={formData} setFormData={setFormData} />,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    const form = event.target
    const formD = new FormData(form)

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formD).toString(),
      })
      navigate("/confirmation")
    } catch (error) {
      console.error("Error submitting form", error)
    }
  }

  return (
    <div className="planner-container">
      <form
        method="post"
        name="planner"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="planner" />
        <label className="hidden">
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
        {sections.map((section, index) => (
          <div
            key={index}
            style={{ display: step === index ? "block" : "none" }}
          >
            {section}
          </div>
        ))}
        <NavigationButtons
          step={step}
          setStep={setStep}
          maxSteps={sections.length}
        />
      </form>
    </div>
  )
}

export default FormPlanner
