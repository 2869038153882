import React from "react"

function StartingPoint({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Point de départ</h3>
      <label htmlFor="startingPoint">Ville de départ</label>
      <input
        type="text"
        id="startingPoint"
        name="startingPoint"
        className="cardBorder"
        value={formData.startingPoint}
        onChange={(e) =>
          setFormData({ ...formData, startingPoint: e.target.value })
        }
        placeholder="Entrez la ville de départ"
      />
    </div>
  )
}

export default StartingPoint
