import React from "react"

function Accommodation({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Préférences d’hébergement</h3>
      <label htmlFor="accommodation">Choisissez votre type d’hébergement</label>
      <select
        id="accommodation"
        name="accommodation"
        className="cardBorder"
        value={formData.accommodation}
        onChange={(e) =>
          setFormData({ ...formData, accommodation: e.target.value })
        }
      >
        <option value="hotels">Hôtels</option>
        <option value="hostels">Auberges de jeunesse</option>
        <option value="airbnb">Airbnb</option>
        <option value="unique">Hébergements atypiques</option>
      </select>
    </div>
  )
}

export default Accommodation
