const normalizeCityName = (cityName) => {
  switch (cityName.toLowerCase()) {
    case "athènes":
      return "athenes"
    case "la valette":
      return "la-valette"
    default:
      return cityName.toLowerCase()
  }
}

module.exports = normalizeCityName
