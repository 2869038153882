import React from "react"
import { Link } from "gatsby"
import normalizeCityName from "../utils/normalizeCityName"

const PostLink = ({ post }) => {
  const normalizedCityName = normalizeCityName(post.frontmatter.city)
  const mapPath = `/carte-touristique-${normalizedCityName}`

  return (
    <div className="card cardBorder">
      <img
        loading="lazy"
        src={`/icons/${post.frontmatter.city}.svg`}
        alt={post.frontmatter.city}
        width="100px"
        height="100px"
      />
      <h2>
        <Link to={mapPath}>{post.frontmatter.city}</Link>
      </h2>
    </div>
  )
}

export default PostLink
