import React from "react"

function Interests({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Centres d’intérêt</h3>
      <label htmlFor="interests">Sélectionnez vos centres d’intérêt</label>
      <select
        id="interests"
        name="interests[]"
        className="cardBorder"
        multiple
        value={formData.interests}
        onChange={(e) =>
          setFormData({
            ...formData,
            interests: [...e.target.selectedOptions].map((o) => o.value),
          })
        }
      >
        <option value="cultural">Sites culturels</option>
        <option value="nature">Nature</option>
        <option value="adventure">Aventure</option>
        <option value="food">Gastronomie</option>
        <option value="art">Art</option>
        <option value="history">Histoire</option>
      </select>
    </div>
  )
}

export default Interests
